<template>
  <v-container class="new-account mt-5 px-10" fluid>
    <PageHeader
      header-text="Edit Account"
      class="mb-7 px-3"
      regular-button-text="Edit Page Settings"
      @regular-button-click="$router.push({name: 'PersonalizationSettings', params: { id: $route.params.id }})"
    >
      <template #subheader>
        <div>Check out the form below to edit the account</div>
      </template>
    </PageHeader>

    <div class="d-flex align-center mb-7">
      <div v-if="!accountFields.logoFile && !accountFields.logoUrl" class="upload-background d-flex align-center justify-center" @click="$refs.logo_container.click()">
        <input ref="logo_container" type="file" accept=".jpg, .jpeg, .png" style="width: 0; height: 0" @change="handle_file_upload">
        <v-icon size="18" color="66788e">
          mdi-upload-outline
        </v-icon>
      </div>
      <div v-else class="upload-background d-flex align-center justify-center">
        <img v-show="accountFields.logoFile" ref="logo_preview" alt="logo preview" style="max-width: 92px; max-height: 92px;">
        <img v-show="accountFields.logoUrl" :src="accountFields.logoUrl" alt="account logo" style="max-width: 92px; max-height: 92px;">
        <v-icon
          size="24" color="warning"
          style="position: absolute;"
          @click="removeLogo()"
        >
          mdi-close
        </v-icon>
      </div>
      <div>
        <div class="title-text mb-1">
          Logo
        </div>
        <div class="text-field-hint">
          Choose your account’s logo that will be visible to other users
        </div>
      </div>
    </div>

    <validation-observer v-slot="{ invalid }">
      <v-row class="py-0">
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            v-model="accountFields.name"
            header="Account Name"
            placeholder="My Account"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomDropdown
            :value="accountFields.accountType"
            header="Account Type"
            placeholder="HCP / Patient"
            :items="accountTypes"
            @change="changeAccountTypeAndDefaultValues($event)"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomDatePicker
            :date.sync="accountFields.subscriptionStartDate"
            placeholder="Pick the date"
            header="Subscription Start Date"
            :required="true"
            @input="(date) => (accountFields.subscriptionStartDate = date)"
          />
        </v-col>
      </v-row>

      <v-row class="py-0">
        <v-col cols="12" sm="4" class="py-0 pt-2">
          <CustomDropdown
            v-model="accountFields.subscriptionTerm"
            header="Subscription Term"
            placeholder="Select an option"
            :items="subscriptionTerms"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0 pt-2">
          <CustomDropdown
            v-model="accountFields.passwordStrengthLevel"
            header="Required Password Strength"
            placeholder="Select an option"
            :items="passwordStrengthLevels"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomCombobox
            v-model="accountFields.agency"
            subheader="Agency"
            :items="accountAgencies"
            item-value="agency"
            item-text="agency"
          />
        </v-col>
        <v-col cols="12" sm="4" class="py-0">
          <CustomTextInput
            v-model="accountFields.website"
            header="Website"
            placeholder="Enter the main website for this account"
          />
        </v-col>
      </v-row>

      <v-divider class="mt-4 mb-8" />

      <!-- custom fields  -->
      <div
        class="d-flex flex-wrap flex-sm-nowrap align-center mb-3 mb-sm-7 justify-center justify-sm-space-between justify-lg-start"
      >
        <div class="field__subheader">
          Add any custom fields you’d like to use within this account
        </div>
        <div
          class="simple-text-button button--blue mx-10 mx-sm-0 ml-lg-9 mt-4 mt-sm-0"
          @click="addCustomField"
        >
          <v-icon color="#2b84eb" size="16" class="mr-0">
            mdi-plus
          </v-icon>
          Add Field
        </div>
      </div>
      <CustomFields
        :custom_fields="customFields"
        @action="(item, field) => handleAction(item, field)"
      />

      <v-divider class="mt-4 mb-8" />
      <div class="text-center mb-5">
        Account Settings
      </div>

      <div class="field__header">
        KPI Email
      </div>
      <v-row class="py-0">
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.email.min_open_rate"
            header="Min Open Rate"
            placeholder="Min Open Rate"
            decimal
            immediate
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.email.max_open_rate"
            header="Max Open Rate"
            placeholder="Max Open Rate"
            decimal
            immediate
          />
        </v-col>
      </v-row>

      <v-row class="py-0">
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.email.min_click_rate"
            header="Min Click Rate"
            placeholder="Min Click Rate"
            decimal
            immediate
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.email.max_click_rate"
            header="Max Click Rate"
            placeholder="Max Click Rate"
            decimal
            immediate
          />
        </v-col>
      </v-row>

      <v-row class="py-0">
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.email.min_ctor"
            header="Min CTOR"
            placeholder="Min CTOR"
            decimal
            immediate
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.email.max_ctor"
            header="Max CTOR"
            placeholder="Max CTOR"
            decimal
            immediate
          />
        </v-col>
      </v-row>

      <v-divider class="mt-4 mb-8" />

      <div class="field__header">
        KPI Banner
      </div>
      <v-row class="py-0">
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.banners.min_click_rate"
            header="Min Click Rate"
            placeholder="Min Click Rate"
            decimal
            immediate
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <CustomTextInput
            v-model="accountFields.kpiSettings.banners.max_click_rate"
            header="Max Click Rate"
            placeholder="Max Click Rate"
            decimal
            immediate
          />
        </v-col>
      </v-row>

      <v-divider class="mt-4 mb-8" />
      <v-row class="mt-10">
        <v-col cols="12" sm="6">
          <div class="field__header mb-5">
            Lead Score Look Back Period
          </div>
          <div class="py-0">
            <v-col cols="12" class="py-0 px-0">
              <CustomTextInput
                v-model="accountFields.leadScoreLookBackPeriod"
                type="number"
                hint="This represents the number of months that the lead score calculation will look back when calculating scores."
              />
            </v-col>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mt-4 mb-8" />
      <v-row class="mt-10">
        <v-col cols="12">
          <div class="field__header mb-5">
            Data Retention Period
          </div>
          <div class="py-0">
            <v-col cols="12" class="py-0 px-0">
              <CustomTextInput
                v-model="accountFields.dataRetention"
                type="number"
                hint="This represents the number of months that we will keep certain data such as actions.
                When an action is older than this number of months, it will be removed from the system."
              />
            </v-col>
          </div>
        </v-col>
      </v-row>

      <v-divider class="mt-4 mb-8" />

      <v-row class="mt-12 mb-10">
        <v-col cols="12" class="d-flex justify-end flex-wrap">
          <v-btn
            class="custom-button custom-button--blue px-13"
            height="34px"
            depressed
            width="150px"
            :block="$vuetify.breakpoint.xsOnly"
            :disabled="invalid"
            @click="saveAccount"
          >
            Save Account
          </v-btn>
        </v-col>
      </v-row>
    </validation-observer>

    <v-dialog v-model="dialog" scrollable max-width="560px">
      <AddOrEditCustomFieldDialog
        v-if="dialog"
        :field="activeCustomField"
        :existed-name="existedName"
        @dismiss="dialog = false; clearActiveCustomField()"
        @save="(data) => saveCustomField(data)"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import CustomTextInput from "@/sharedComponents/CustomTextInput";
import CustomDropdown from "@/sharedComponents/CustomDropdown";
import CustomDatePicker from "@/sharedComponents/CustomDatePicker";
import CustomFields from "@/views/Accounts/components/CustomFields";
import AddOrEditCustomFieldDialog from "@/views/Accounts/components/AddOrEditCustomFieldDialog";
import CustomCombobox from "@/sharedComponents/CustomCombobox";
import {parseImageFileToBase64} from "@/utils/helper";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: "EditAccountView",
  metaInfo: {
    title: 'Edit Account'
  },
  components: {
    PageHeader,
    CustomTextInput,
    CustomDropdown,
    CustomDatePicker,
    CustomFields,
    AddOrEditCustomFieldDialog,
    CustomCombobox
  },
  data() {
    return {
      logo_preview: '',
      accountFields: {
        accountId: null,
        logoFile: null,
        logoUrl: null,
        name: null,
        accountType: null,
        accountStatus: null,
        subscriptionTerm: null,
        passwordStrengthLevel: null,
        subscriptionStartDate: '',
        editType: null,
        editChoice: null,
        searchType: null,
        searchChoice: null,
        canAddNewValue: false,
        isViewable: false,
        agency: null,
        website: null,
        customFieldDefinition: {
          contact: [],
        },
        kpiSettings: {
          email: {
            min_open_rate: 5,
            max_open_rate: 12,
            min_click_rate: 1,
            max_click_rate: 3,
            min_ctor: 20,
            max_ctor: 25,
          },
          banners: {
            min_click_rate: 0.1,
            max_click_rate: 2.0
          }
        },
      },
      activeCustomField: {},
      dialog: false,
      customFields: [],
      accountStatuses: [
        { label: "New", value: "new" },
        { label: "Live", value: "live" },
      ],
      accountTypes: [
        { label: "HCP", value: "HCP" },
        { label: "Patient", value: "Patient" },
      ],
      subscriptionTerms: [
        { label: "1 month", value: 1 },
        { label: "3 months", value: 3 },
        { label: "1 year", value: 12 },
      ],
      passwordStrengthLevels: [
        { label: "Medium", value: "2" },
        { label: "Strong", value: "3" },
        { label: "Very Strong", value: "4" }
      ],
      accountAgencies: [],
    };
  },
  computed: {
    existedName() {
      return this.customFields.map(field => field.name);
    }
  },
  async created() {
    const { data } = await this.getAccount();

    this.setCurrentAccount(data);
    await this.getAgencies();
  },
  methods: {
    async getAccount() {
      return await this.$rest.account.get_resource(+this.$route.params.id);
    },
    setCurrentAccount(account) {
      this.accountFields = {
        ...this.accountFields,
        ...JSON.parse(JSON.stringify(account)),
        accountStatus: 'live',
      }
      this.customFields = JSON.parse(JSON.stringify(account.customFieldDefinition.contact));
      this.customFields = this.customFields.map(field => ({...field, id: uuidv4()}));
    },
    removeLogo() {
      this.accountFields.logoFile = null;
      this.accountFields.logoUrl = null;
    },
    changeAccountTypeAndDefaultValues(type) {
      this.accountFields.accountType = type

      this.accountFields.kpiSettings.email = {
        min_open_rate: type === 'Patient' ? 11 : 5,
        max_open_rate:  type === 'Patient' ? 15 : 12,
        min_click_rate: type === 'Patient' ? 3 : 1,
        max_click_rate: type === 'Patient' ? 5 : 3,
        min_ctor: type === 'Patient' ? 27 : 20,
        max_ctor: type === 'Patient' ? 33 : 25,
      }
    },
    async handle_file_upload(event) {
      let file = null;
      if (event.target.files.length) {
        file = event.target.files[0];
      }

      if (!(file instanceof File)) {
        this.accountFields.logoFile = null;
        this.$refs.logo_preview.src = '';
        return;
      }

      parseImageFileToBase64(file).then(imgLoaded => {
        this.accountFields.logoFile = {
          data: imgLoaded.data,
          fileName: imgLoaded.name,
        };
        this.$nextTick(() => {
          this.$refs.logo_preview.src = imgLoaded.data;
        })
      }).catch(() => {
        this.accountFields.logoFile = null;
        this.$refs.logo_preview.src = '';
      });
    },
    async saveAccount() {
      this.accountFields.customFieldDefinition.contact = this.customFields;

      const data = {
        ...this.accountFields,
        subscriptionTerm: parseInt(this.accountFields.subscriptionTerm),
      };

      await this.$rest.account.put_resource(this.accountFields.id, data)

      this.$store.commit('snackbar/showMessage', {
        color: "success",
        content: "Account successfully added.",
      })

      await this.$router.push({name: "Accounts"});
    },
    addCustomField() {
      this.clearActiveCustomField();
      this.dialog = true;
    },
    clearActiveCustomField() {
      this.activeCustomField = {};
    },
    async handleAction(item, field) {
      this.activeCustomField = { ...field };
      if (item.value === "delete") {
        for (let i = 0; i < this.customFields.length; i++) {
          if (field.name === this.customFields[i].name) {
            this.customFields.splice(i, 1);
            break;
          }
        }
      } else if (item.value === "edit") {
        this.dialog = true;
      }
    },
    async saveCustomField(field) {
      for (let i = 0; i < this.customFields.length; i++) {
        if (field.id === this.customFields[i].id) {
          this.$set(this.customFields, i, field);
          return;
        }
      }
      this.customFields.push(field);
    },
    async getAgencies() {
      this.accountAgencies = (await this.$rest.account.getAgencies()).data;
    }
  },
};
</script>

<style scoped lang="scss">
.new-account {
  .upload-background {
    cursor: pointer;
    width: 95px;
    min-width: 95px;
    height: 95px;
    margin: 0 14px 0 0;
    border-radius: 3px;
    background-color: #ecf1f5;
    * {
      cursor: pointer;
    }
  }

  .v-input.v-input--selection-controls {
    margin: 0;
    .v-input__slot {
      margin-bottom: 0;
    }
  }
}
.field {
  &__header {
    margin-bottom: 25px;
  }
}
</style>
